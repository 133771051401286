<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Sales" subtitle="Manage the sales made during and after registration.">

		<app-content-head-actions-panel v-if="collection.length" text="Export" icon="export" component="manager/common/panel/Export" :data="exportData" />
	
	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-check v-model="filter.itemised" text="Itemised" tooltip="View itemised list" />
		<app-content-filters-find v-if="filter.itemised" v-model="filter.variants" api="convention/registration/item/variant" text="Items" tooltip="Filter by item" />
		<app-content-filters-checklist v-model="filter.type" text="Type" tooltip="Filter by type" :options="$util.options($constants.registration.sale.types)" />
		<app-content-filters-checklist v-model="filter.source" text="Source" tooltip="Filter by source" :options="filterSourceOptions" />
		<app-content-filters-checklist v-model="filter.status" text="Status" tooltip="Filter by status" :options="filterStatusOptions" />
		<app-content-filters-date v-model="filter.from" tooltip="Filter from date" text="From" :max="filter.to" />
		<app-content-filters-date v-model="filter.to" tooltip="Filter to date" text="To" :min="filter.from" />

	</app-content-filters>

	<app-content-body v-if="is.initialised" :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no sales found.">

		<app-table>

			<app-table-head :layout="layout" v-if="filter.itemised">

				<app-table-column text="Sale" />
				<app-table-column text="Status" />
				<app-table-column text="Name" />
				<app-table-column text="Item" />
				<app-table-column text="Date" />
				<app-table-column text="Item Type" />
				<app-table-column text="Sale Type" />
				<app-table-column text="Total" />

			</app-table-head>

			<app-table-head :layout="layout" v-if="!filter.itemised">

				<app-table-column text="No" />
				<app-table-column text="Status" />
				<app-table-column text="Name" />
				<app-table-column text="Date" />
				<app-table-column text="Type" />
				<app-table-column align="center" text="Badges" />
				<app-table-column align="center" text="Items" />
				<app-table-column text="Total" />

			</app-table-head>

			<app-table-body v-if="filter.itemised">

				<app-table-row v-on:click="onEditClick(item.sale.id)" :danger="item.status === $constants.registration.sale.status.refunded" :warning="item.status === $constants.registration.sale.status.partial" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.sale.reference" />
					<app-table-cell-label :type="$constants.registration.sale.statusType[item.status]" :text="$constants.registration.sale.status[item.status]" />
					<app-table-cell-text :text="item.sale.user.name" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.sale.date.made | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="$constants.registration.item.typeName[item.type]" />
					<app-table-cell-text :text="$constants.registration.sale.types[item.sale.type]" />
					<app-table-cell-text :text="item.price| formatMoney" />

				</app-table-row>

			</app-table-body>

			<app-table-body v-if="!filter.itemised">

				<app-table-row v-on:click="onEditClick(item.id)" :danger="item.status === $constants.registration.sale.status.refunded" :warning="item.status === $constants.registration.sale.status.partial" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-label :type="$constants.registration.sale.statusType[item.status]" :text="$constants.registration.sale.status[item.status]" />
					<app-table-cell-text :text="item.user.name" />
					<app-table-cell-text :text="item.date.made | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="$constants.registration.sale.types[item.type]" />
					<app-table-cell-text :text="item.count.badges + ((item.count.stacked) ? ' (+' + item.count.stacked + ')' : '')" align="center" />
					<app-table-cell-text :text="item.count.addons" align="center" />
					<app-table-cell-text :text="item.cost.total | formatMoney" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				sources: []
			},
			filter: {
				itemised: false,
				status: [],
				variants: [],
				type: [],
				source: [],
				from: 0,
				to: 0
			},
			filterStatusOptions: [
				{ value: 1, text: 'Completed' },
				{ value: 2, text: 'Refunded' },
				{ value: 3, text: 'Partial Refund' }
			],
			exportColumns: {
				items: {
					sale_reference: 'Sale Reference',
					sale_type: 'Sale Type',
					type: 'Item Type',
					sale_source: 'Sale Source',
					status: 'Status',
					sale_date_made: 'Date Made',
					date_refunded: 'Date Refunded',
					user_firstname: 'First name',
					user_lastname: 'Last name',
					item: 'Item',
					user_email: 'Email',
					total: 'Total'
				},
				sales: {
					reference: 'Reference',
					status: 'Status',
					type: 'Type',
					source: 'Source',
					date_made: 'Date made',
					date_refunded: 'Date refunded',
					firstname: 'First name',
					lastname: 'Last name',
					email: 'Email',
					badges: 'Badge count',
					addons: 'Item count',
					total: 'Total'
				}
			},
			live: 'convention/registration/sale'
		}

	},

	watch: {

		itemised: function() {

			this.is.initialised = false

		}

	},

	computed: {
		
		itemised: function() {

			return this.filter.itemised

		},

		endpoint: function() {

			return (this.filter.itemised) ? 'convention/registration/sales/items' : 'convention/registration/sales'

		},

		layout: function() {

			if (this.filter.itemised) {

				return '60px 120px 150px auto 150px 150px 120px 80px'

			} else {

				return '60px 120px auto 150px 120px 80px 80px 80px'

			}

		},

		filterSourceOptions: function() {

			var sources = []

			this.$_.each(this.references.sources, function(source) {

				sources.push({
					value: source.id,
					text: source.name
				})

			})

			return sources

		},

		exportData: function() {

			return {
				columns: (this.itemised) ? this.exportColumns.items : this.exportColumns.sales,
				requiredColumns: [],
				valueMapping: {},
				params: this.getParams(),
				index: this.selected,
				endpoint: this.endpoint
			}

		}

	}

}

</script>

<style scoped>

</style>